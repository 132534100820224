import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "welcome"
    }}>{`Welcome`}</h1>
    <p>{`The LiveSession API gives developers access to the features via a JavaScript API, Rest API and Webhooks.`}</p>
    <h3 {...{
      "id": "table-of-content"
    }}>{`Table of content`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/javascript-api/introduction/"
        }}><strong parentName="a">{`JavaScript API`}</strong></a>{` - Customize your expirience with LiveSession using JavaScript functions and events.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/rest-api/introduction/"
        }}><strong parentName="a">{`Rest API`}</strong></a>{` - Retrieve and update data using REST API.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/webhooks/introduction/"
        }}><strong parentName="a">{`Webhooks`}</strong></a>{` - Keep informed by receiving notifications when particular actions occur.`}</li>
    </ul>
    <h3 {...{
      "id": "support"
    }}>{`Support`}</h3>
    <p>{` If you have any questions, just start a conversation with our support team using Intercom or reach us at `}<a parentName="p" {...{
        "href": "mailto:hello@livesession.io"
      }}>{`hello@livesession.io`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      